import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Deadline",
  "time": "stuff",
  "index": 7,
  "type": "project",
  "hidden": false,
  "splash": "Minimalistic method of organizing reminders with seamless workflow integration"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/deadline"
      }}>{`Deadline`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`Angular`}</inlineCode>{` `}<inlineCode parentName="p">{`Firestore`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/deadline" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Deadline is a simple application for organizing goals in your life. Deadline is secured by OAuth to keep your tasks your own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      